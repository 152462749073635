<template>
  <div class="pic-404">
    <img class="pic-404__center" src="@/assets/image/mobile404.png" alt="404">
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {},
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.pic-404{
  .pic-404__center{
    width: 100%;
    transform: translate(-50%,-50%);
    position: absolute;
    top: 45%;
    left: 50%;
  }
}
</style>
